<template>
    <div class="container-fluid">
        <div class="col-md-12">
            <h4 class="title-in-h">{{info_page.header}}</h4>
        </div>
        <breadcrumb >
            <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                <router-link :to="{path:breadcrumb.path}">{{breadcrumb.name}}</router-link>
            </breadcrumb-item>
            <breadcrumb-item active v-else>
                {{breadcrumb.name}}
            </breadcrumb-item>
        </breadcrumb>

        <card class="mt-2">
        <div>
            <!--   Hacer el cambio de idioma
           <div class="row d-none">
                <div class="col-md-8">
                    <div class="btn-taps text-center mt-2 mb-1">

                        <button v-for="i_lang in info_page.langs" class="btn btn-wd btn-default ml-1 mr-1" :class="info_page.tab_lang===i_lang.locale?'btn-info':'btn-outline'" @click="info_page.tab_lang=i_lang.locale">{{i_lang.name}}</button>

                    </div>
                </div>
            </div>  ***************************      -->



            <vue-topprogress ref="topProgress"></vue-topprogress>
            <form>
                <div class="row">

                    <div class="col-md-4">
                        <fg-input type="text"
                                  :name="$t('form.name')"
                                  :label="$t('form.name')"
                                  :placeholder="$t('form.name')"
                                  v-validate="modelValidations.name"
                                  :error="getError($t('form.name'))"
                                  v-model="contract.es.name">
                        </fg-input>
                       <!-- <fg-input type="text"
                                  :name="$t('form.name_lang')"
                                  :label="$t('form.name_lang')"
                                  :placeholder="$t('form.name_lang')"

                                  :error="getError($t('form.name_lang'))"
                                  v-show="info_page.tab_lang==='en'"
                                  v-model="contract.en.name">
                        </fg-input>-->

                    </div>
                    <div class="col-md-4">
                        <fg-input type="text"
                                  @input="contract.cod = contract.cod.toUpperCase()"
                                  :name="$t('contract.form.code')"
                                  :label="$t('contract.form.code')"
                                  :placeholder="$t('contract.form.code')"
                                  v-validate="modelValidations.code"
                                  :error="getError($t('contract.form.code'))"
                                  v-model="contract.cod"
                                  :disabled="(info_page.action==='edit')"
                        >
                        </fg-input>

                    </div>
                    <div class="col-md-4">
                        <div class="form-check is_public">
                            <label for="is_public" class="form-check-label check-admin">
                                <input class="form-check-input" type="checkbox" id="is_public" v-model="contract.is_public">
                                <span class="form-check-sign" ></span> Público
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="contract_info.contract_all.length">

                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('contract.form.vinculo')}}</label>
                            <el-select class="select-default" size="large" :placeholder="$t('contract.form.vinculo')"
                                       v-model="contract.link_rateplan" @change="contractType">
                                <el-option v-for="option in contract_info.contracts" class="select-default" :value="option._id"
                                           :label="option.name" :key="option._id">
                                </el-option>
                            </el-select>
                        </div>

                    </div>
                    <div class="col-md-4">
                        <div class="form-group has-label pt-custom" v-show="contract.link_rateplan!==''">
                            <el-select class="select-default" size="large" :placeholder="$t('contract.form.link_type')"  :name="$t('contract.form.link_type')"
                                       v-model="contract.link_type" @change="changeLinkType(contract.link_type)">
                                <el-option v-for="option in contract_info.format_payment" class="select-default" :value="option._id"
                                           :label="option.name" :key="option._id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-4  pt-custom"  v-show="contract.link_rateplan!==''">
                        <fg-input type="text"
                                  :name="$t('contract.form.link_value')"
                                  :placeholder="$t('contract.form.link_value')"
                                  v-model="contract.link_value"
                                  :disabled="contract.link_type=='='?true:false"
                                  @input="ContractInfo"
                        >
                        </fg-input>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('contract.form.type')}}</label>

                            <fg-input v-show="contract_base"
                                    v-validate="modelValidations.type"  :name="$t('contract.form.type')"  v-model="contract.meal_plan"
                                    :error="getError($t('contract.form.type'))">
                                <el-select class="select-default" size="large" :placeholder="$t('contract.form.type')"  v-validate="modelValidations.type" :name="$t('contract.form.type')"
                                           v-model="contract.meal_plan"  :error="getError($t('contract.form.type'))" @change="checkType()"
                                          >
                                    <el-option v-for="option in contract_info.types" class="select-default" :value="option._id"
                                               :label="option.name" :key="option._id" v-if="option.status">
                                    </el-option>
                                </el-select>
                            </fg-input>


                            <fg-input  v-show="!contract_base"
                                      v-validate="modelValidations.type"  :name="$t('contract.form.type')"  :value="contractMealplan(contract.meal_plan)"
                                      :error="getError($t('contract.form.type'))" disabled="true">
                            </fg-input>




                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('contract.form.currency')}}</label>


                            <fg-input v-show="(info_page.action==='add') && contract_base"
                                      v-validate="modelValidations.currency"  :name="$t('contract.form.currency')"  v-model="contract.currency"
                                      :error="getError($t('contract.form.currency'))">
                                <el-select class="select-default" size="large" :placeholder="$t('contract.form.currency')"  v-validate="modelValidations.currency" :name="$t('contract.form.currency')"
                                           v-model="contract.currency"  :error="getError($t('contract.form.currency'))" >
                                    <el-option v-for="option in contract_info.currencys" class="select-default"  :value="option._id"
                                               :label="option.name" :key="option._id">
                                    </el-option>
                                </el-select>
                            </fg-input>

                            <fg-input v-show="(info_page.action==='add') && !contract_base"
                                      v-validate="modelValidations.currency"  :name="$t('contract.form.currency')"  v-model="contract.currency"
                                      :error="getError($t('contract.form.currency'))" disabled="true">
                            </fg-input>


                            <fg-input type="text"
                                      v-model="contract.currency"
                                      :disabled="(info_page.action==='edit')"
                                      v-show="info_page.action==='edit'"
                            >
                            </fg-input>







                        </div>
                    </div>

                    <div class="col-md-4">

                    </div>
                </div>



                <div class="box-rooms"  v-show="!room_linked">
                    <!--<div class="row" v-show="contract_base || contract.meal_plan<3">-->
                        <div class="row" >


                        <div class="offset-3 col-md-9 pb-0 pl-5">
                            <h4 class="mb-0 title-b">Resultado tarifas vinculadas</h4>
                        </div>

                        <div class="col-md-4 pb-0">
                            <label >{{$t('contract.form.add_rooms')}}</label>
                        </div>
                        <div class="col-md-8 pb-0" v-if="contract.meal_plan===3">
                            <div class="row">
                                <div class="col-md-2 pl-3 pb-0">

                                    <label >{{$t('contract.form.simple')}}</label>
                                </div>
                                <div class="col-md-2 pl-3 pb-0">
                                    <label >{{$t('contract.form.double')}}</label>
                                </div>
                                <div class="col-md-2 pl-3 pb-0">

                                    <label >{{$t('contract.form.triple')}}</label>
                                </div>
                                <div class="col-md-2 pl-3 pb-0">

                                    <label >{{$t('contract.form.cuadruple')}}</label>
                                </div>
                                <div class="col-md-2 pl-3 pb-0">
                                    <label >{{$t('contract.form.jr')}}</label>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-8 pb-0" v-if="contract.meal_plan<=2">
                            <div class="row">
                                <div class="col-md-2 pb-0">

                                    <label >{{$t('contract.form.rate_base')}}</label>
                                </div>
                                <div class="col-md-2 pb-0">
                                    <label >{{$t('contract.form.extra_pax')}}</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row" v-for="(room, n) in contract.rooms">
                        <div class="col-md-4 custom-error">
                            <fg-input v-model="room.room_id" v-show="contract_base || room.room_id ===''"
                                      :name="$t('contract.form.add_rooms')">
                                <el-select class="select-default" size="large"  :name="$t('contract.form.type')"
                                           v-model="room.room_id" @change="checkDifference(n, 'change')">
                                    <el-option v-for="(option, idx) in contract_info.rooms" class="select-default" :value="option._id"
                                               v-show="option.status===true" :label="option.name" :key="idx">
                                    </el-option>
                                </el-select>
                            </fg-input>


                            <fg-input  v-show="!contract_base && room.room_id !==''"
                                       v-validate="modelValidations.type"  :name="$t('contract.form.type')"  :value="roomName(room.room_id)"
                                       :error="getError($t('contract.form.type'))" disabled="true">
                            </fg-input>

                            <label class="text-danger " v-show="contract.errors.rooms[n]">Es obligatorio agregar una habitación para continuar</label>
                        </div>
                        <div class="col-md-8"  v-show="contract.meal_plan===3">
                            <div class="row" >
                                <div class="col-md-2 pt-0">
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.sencilla"
                                              v-show="contract_base"
                                    >
                                    </fg-input>

                                    <label v-show="!contract_base" class="text-muestra" >${{room.sencilla}}</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.double"
                                              v-show="contract_base">
                                    </fg-input>
                                    <label v-show="!contract_base" class="text-muestra" >${{room.double}}</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.triple"
                                              v-show="contract_base">
                                    </fg-input>
                                    <label v-show="!contract_base" class="text-muestra" >${{room.triple}}</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.cuadruple"
                                              v-show="contract_base">
                                    </fg-input>
                                    <label v-show="!contract_base" class="text-muestra" >${{room.cuadruple}}</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.jr">
                                    </fg-input>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <a class="item-minus" v-tooltip.bottom="'Eliminar habitación'">
                                        <i class="fa fa-minus"  @click="deleteRoom(n)" v-if="contract.rooms.length>1 && n !== 0"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8" v-show="contract.meal_plan<3 ">
                            <div class="row">
                                <div class="col-md-2 pt-0 custom-error" >
                                    <fg-input type="text"
                                              :name="$t('contract.form.rate_base')"
                                              v-model="room.base_rate"
                                              @input="contract.errors.rates[n] = false"
                                              v-show="contract_base"
                                    >
                                    </fg-input>
                                    <label v-show="!contract_base" class="text-muestra" >${{room.base_rate}}</label>

                                    <label class="text-danger "  v-show="contract.errors.rates[n]">El campo Tarifa base es obligatorio</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.extra_pax"
                                              v-show="contract_base">
                                    </fg-input>
                                    <label v-show="!contract_base" class="text-muestra">${{room.extra_pax}}</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <a class="item-minus" v-tooltip.bottom="'Eliminar habitación'">
                                        <i class="fa fa-minus"  @click="deleteRoom(n)" v-if="contract.rooms.length>0 "></i>
                                    </a>
                                </div>
                            </div>


                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-8 pb-0 offset-4" v-if="contract.meal_plan===3">
                            <div class="row">
                                <div class="col-md-12">
                                    <h4>Resultado tarifas vinculadas</h4>
                                </div>
                                <div class="col-md-2 pb-0">

                                    <label >{{$t('contract.form.simple')}}</label>
                                </div>
                                <div class="col-md-2 pb-0">
                                    <label >{{$t('contract.form.double')}}</label>
                                </div>
                                <div class="col-md-2 pb-0">

                                    <label >{{$t('contract.form.triple')}}</label>
                                </div>
                                <div class="col-md-2 pb-0">

                                    <label >{{$t('contract.form.cuadruple')}}</label>
                                </div>
                                <div class="col-md-2 pb-0 d-none">
                                    <label >{{$t('contract.form.jr')}}</label>
                                </div>

                            </div>
                        </div>
                    </div>


                    <!--<div class="row" v-for="(room, n) in contract.rooms"  v-if="contract.meal_plan===3">
                        <div class="col-md-4 custom-error" >
                            <label>{{roomName(room.room_id)}}</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row" >
                                <div class="col-md-2 pt-0">
                                    <label>{{room.sencilla}}</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <label>{{room.double}}</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <label>{{room.triple}}</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <label>{{room.cuadruple}}</label>
                                </div>
                                <div class="col-md-2 pt-0 d-none">
                                    <label>{{room.jr}}</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                </div>
                            </div>
                        </div>
                    </div>-->



                    <div class="row">
                        <div class="col-md-12 text-right">
                            <a class="btn-plus"  v-show="contract.rooms.length < contract_info.rooms.length ">
                                <i class="fa fa-plus" @click="addRoom()" v-tooltip.bottom="'Agregar habitación'"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="row" v-show="contract_base">

                    <div class="box-room-links" :class="show_extendible?'extendible':'normal'">
                        <div class="head-section">
                            <div class="row">
                                <div class="col-md-9">
                                    <div class="box-header">
                                        <div class="form-check">
                                            <label for="is_public2" class="form-check-label check-admin subtitle">
                                                <input class="form-check-input" type="checkbox" id="is_public2" v-model="room_linked">
                                                <span class="form-check-sign" ></span>
                                                Habitaciones vinculadas
                                            </label>
                                        </div>
                                        <label class="lbl-subtitle">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Todas las habitaciones se vinculan a la habitación base. </label>
                                    </div>
                                </div>

                                <div class="col-md-3"  v-show="room_linked">
                                    <h4 class="results-rates" v-show="!show_extendible">Resultado tarifas vinculadas</h4>
                                </div>
                            </div>
                            <div class="row" v-show="room_linked">
                                <div class="offset-3 pb-0" :class="show_extendible?'col-md-9':'col-md-6'">
                                    <div class="box-links-rates">
                                        <div class="group-item" v-for="head in info_page.info_links.title.normal" v-show="!show_extendible">
                                            <label>{{head}}</label>
                                        </div>
                                        <div class="group-item" v-for="head in info_page.info_links.title.extendible" v-show="show_extendible">
                                            <label>{{head}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="box-resume"  v-if="!show_extendible">
                                        <label v-for="head in info_page.info_links.title.normal" v-show="!show_extendible">{{head}}</label>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="item-link" v-for="(room_link, idx_) in contract.room_links"  v-show="room_linked">

                            <div class="row ml-0 mr-0">
                                <div class="col-md-3">
                                    <i class="fa fa-star" v-show="(idx_===0)" v-tooltip.bottom="'Habitación base'"></i>
                                    <el-select class="select-default primary" :class="(idx_===0)?'class_base':'no-icon'" size="large" :placeholder="(idx_===0)?'Seleccionar hab. base':'Seleccionar habitación'"
                                               v-model="room_link.room_id" @change="checkDifferenceLink()">
                                        <el-option v-for="(option, idx) in contract_info.rooms" class="select-default" :value="option._id"
                                                   v-show="option.status===true" :label="option.name" :key="idx">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div :class="show_extendible?'col-md-9':'col-md-6'">
                                    <div class="box-links-rates">
                                        <div class="group-normal" v-if="!show_extendible">
                                            <div class="group-item">
                                                <fg-input type="text"
                                                          class="input-default"
                                                          :class="(idx_===0)?'class_base':''"
                                                          placeholder=""
                                                          v-model="room_link.base_rate.value">
                                                </fg-input>
                                                <el-select class="select-default"  size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                           v-model="room_link.base_rate.link" @change="changeLinkType(room_link.base_rate.link)" v-show="(idx_!==0)">
                                                    <el-option v-for="option in contract_info.format_payment" :value="option._id"
                                                               :label="option.name" :key="option._id">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div class="group-item">
                                                <fg-input type="text"
                                                          class="input-default"
                                                          placeholder=""
                                                          :class="(idx_===0)?'class_base':''"
                                                          v-model="room_link.extra_pax.value">
                                                </fg-input>
                                                <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                           v-model="room_link.extra_pax.link" @change="changeLinkType(room_link.base_rate.link)">
                                                    <el-option v-for="option in contract_info.format_payment" class="select-default" :value="option._id"
                                                               :label="option.name" :key="option._id">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                        </div>
                                        <div class="group-extendible" v-if="show_extendible">
                                            <div class="group-item">
                                                <fg-input type="text"
                                                          class="input-default"
                                                          placeholder=""
                                                          v-model="room_link.sencilla.value"
                                                >
                                                </fg-input>
                                                <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                           v-model="room_link.sencilla.link" @change="changeLinkType(room_link.sencilla.link)">
                                                    <el-option v-for="option in contract_info.format_payment" :value="option._id"
                                                               :label="option.name" :key="option._id">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div class="group-item">
                                                <fg-input type="text"
                                                          class="input-default"
                                                          placeholder=""
                                                          :class="(idx_===0)?'class_base':''"
                                                          v-model="room_link.double.value">
                                                </fg-input>
                                                <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                           v-model="room_link.double.link" @change="changeLinkType(room_link.double.link)"
                                                           v-show="(idx_!==0)">
                                                    <el-option v-for="option in contract_info.format_payment" class="select-default" :value="option._id"
                                                               :label="option.name" :key="option._id">
                                                    </el-option>
                                                </el-select>
                                            </div>

                                            <div class="group-item">
                                                <fg-input type="text"
                                                          class="input-default"
                                                          placeholder=""
                                                          v-model="room_link.triple.value">
                                                </fg-input>
                                                <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                           v-model="room_link.triple.link" @change="changeLinkType(room_link.triple.link)">
                                                    <el-option v-for="option in contract_info.format_payment" :value="option._id"
                                                               :label="option.name" :key="option._id">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div class="group-item">
                                                <fg-input type="text"
                                                          class="input-default"
                                                          placeholder=""
                                                          v-model="room_link.cuadruple.value">
                                                </fg-input>
                                                <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                           v-model="room_link.cuadruple.link" @change="changeLinkType(room_link.cuadruple.link)">
                                                    <el-option v-for="option in contract_info.format_payment" class="select-default" :value="option._id"
                                                               :label="option.name" :key="option._id">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <!--<div class="group-item">
                                                <fg-input type="text"
                                                          class="input-default"
                                                          placeholder=""
                                                          v-model="room_link.jr.value">
                                                </fg-input>
                                                <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                           v-model="room_link.jr.link" @change="changeLinkType(room_link.jr.link)">
                                                    <el-option v-for="option in contract_info.format_payment" :value="option._id"
                                                               :label="option.name" :key="option._id">
                                                    </el-option>
                                                </el-select>
                                            </div>-->
                                            <div class="item-minus">
                                                <i class="fa fa-minus"  @click="deleteLinkRoom(idx_)"></i>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div class="col-md-3"  v-show="!show_extendible">
                                    <div class="box-resume w-border">
                                       <label>${{calculateResults(idx_, 'base_rate')}}</label>
                                        <label>${{calculateResults(idx_, 'extra_pax')}}</label>
                                    </div>
                                    <div class="item-minus">
                                        <i class="fa fa-minus"  @click="deleteLinkRoom(idx_)"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-12 text-right"  v-show="room_linked">
                        <a class="btn-plus room-link"  >
                            <i class="fa fa-plus" @click="addRoomLink()" v-tooltip.bottom="'Agregar habitación'"></i>
                        </a>
                    </div>

                    <div class="box-resume-extendible" v-show="show_extendible && room_linked">
                        <h4>Resultado tarifas vinculadas</h4>
                        <div class="row">
                            <div class="col-md-3">

                                <label class="big-size head">Habitación</label>
                            </div>
                            <div class="col-md-9 padding-15x">
                                <label v-for="head in info_page.info_links.title.extendible" v-show="show_extendible" class="head">{{head}}</label>
                            </div>
                        </div>
                        <div class="item-link" v-for="(room_link, idx) in contract.room_links">
                            <div class="row">
                                <div class="col-md-3">
                                   <label class="big-size">{{roomName(room_link.room_id)}}</label>
                                </div>
                                <div class="col-md-9">

                                    <label>${{calculateResultsAI(idx, 'sencilla')}}</label>
                                    <label>${{calculateResultsAI(idx, 'double')}}</label>
                                    <label>${{calculateResultsAI(idx, 'triple')}}</label>
                                    <label>${{calculateResultsAI(idx, 'cuadruple')}}</label>
                                    <!--<label>$1000</label>-->
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-4 custom-error">
                        <label >{{$t('contract.form.type_payment')}}</label>
                        <div class="form-check"  v-for="payment in contract_info.payments" v-if="payment.status">
                            <label :for="payment._id" class="form-check-label">
                                <input class="form-check-input" type="checkbox" :id="payment._id" v-model="contract.payment_options" :value="payment._id"
                                       @input="contract.errors.payments = false">
                                <span class="form-check-sign" ></span>{{payment.name}}

                            </label>
                        </div>
                        <span v-if="!info_page.payments_status" class="item-empty">No tienes agregado ningún tipo de pago</span>

                        <label class="text-danger "  v-show="contract.errors.payments">Es obligatorio agregar un tipo de pago para continuar</label>
                        <!--<span>Checked names: {{ contract.payment_options }}</span>-->

                    </div>

                    <!-- <div class="col-md-4">
                         <div class="form-group has-label">
                             <label >{{$t('room.form.type_room')}}</label>
                             <el-select class="select-default" size="large" :placeholder="$t('room.form.type_room')"  v-validate="modelValidations.type_room" :name="$t('room.form.type_room')"
                                        v-model="room.type_room"  :error="getError($t('room.form.type_room'))">
                                 <el-option v-for="option in room_info.types" class="select-default" :value="option.label"
                                            :label="option.label" :key="option.label">
                                 </el-option>
                             </el-select>
                         </div>
                     </div>-->
                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('contract.form.politics')}}</label>
                            <fg-input v-model="contract.cancellation_policy" >
                                <el-select class="select-default" size="large"  :name="$t('contract.form.type')"
                                           v-model="contract.cancellation_policy" @change="checkDifference()">
                                    <el-option v-for="(option, idx) in contract_info.annulments" class="select-default" :value="option._id"
                                               :label="option.name" :key="idx">
                                    </el-option>
                                </el-select>
                            </fg-input>
                        </div>
                    </div>
                </div>
                <div class="row pl-3" v-show="contract_base">
                    <div class="col-md-6 mt-3  custom-error">
                        <label class="mb-2 subtitle" >Tarifas</label>
                        <div class="form-rates-ext">
                            <div class="item-group" v-for="(rate, ky) in contract_info.rates">
                                <label>{{rate.name}}</label>
                                <fg-input v-model="rate.value" @input="contract.errors.racks = false">
                                </fg-input>
                                <span class="fa fa-minus"  @click="deleteRack(ky)" ></span>
                            </div>
                            <div class="item-group">
                                <span class="fa fa-plus-circle plus-item" @click="addRack"></span>
                            </div>
                        </div>
                        <label class="text-danger "  v-show="contract.errors.racks">Se deben llenar todos los campos antes de continuar</label>
                    </div>
                </div>

                <div class="text-center mt-3 mb-3">
                    <button type="submit" class="btn btn-info btn-fill btn-wd mr-2" @click.prevent="sendForm(info_page.action)">
                        {{info_page.button}}
                    </button>
                    <button type="buttton" class="btn btn-danger btn-fill btn-wd" @click.prevent="$router.push({name: 'Contracts'})">
                        {{$t('form.cancel')}}
                    </button>
                </div>
                <div class="clearfix"></div>
            </form>
        </div>
    </card>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import {Select, Option, Tag} from 'element-ui'
    import { Checkbox, Breadcrumb, BreadcrumbItem } from 'src/components/index'
    import RoomService from '../../../js/services/RoomService';
    import ContractService from '../../../js/services/ContractService';
    import AnnulmentService from '../../../js/services/AnnulmentService';
    import PropertyService from '../../../js/services/PropertyService';
    import {VTab, VueTabs} from 'vue-nav-tabs'
    import Fuse from 'fuse.js'
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress';


    const roomService = new RoomService();
    const contractService = new ContractService();
    const annulmentService = new AnnulmentService();
    const propertyService = new PropertyService();
    import { mapGetters } from "vuex";

    export default {
        components: {
            Checkbox,
            Breadcrumb,
            BreadcrumbItem,
            [Option.name]: Option,
            [Select.name]: Select,
            [Tag.name]: Tag,
            vueTopprogress
        },
        computed: {
          ...mapGetters(["selected_property"]),
        },
        data() {
            return {
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },

                    {
                        name: this.$t('contract.index.title'),
                        path: '/admin/contracts',
                        type: '',
                    },

                    {
                        name: this.$t('contract.new.header'),
                        path: '',
                        type: 'active',
                    }

                ],
                contract_base: true,
                room_linked: false,

                checkboxes: {
                    first: false,
                    second: false,
                    a: false,
                    b: false,
                    c: false,
                    unchecked: false,
                    checked: true,
                    disabledUnchecked: false,
                    disabledChecked: true
                },

                show_extendible: false,
                info_page:{
                    action: 'add',
                    header: this.$t('contract.new.title'),
                    button:  this.$t('form.save'),
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    show_array: false,
                    payment:{
                        pay_hotel: false,
                        pay_paypal: false,
                        pay_tdc: false
                    },
                    payments_status: false,
                    info_links:{
                        title:{
                            normal: ['Tarifa Base', 'Extra Pax'],
                            extendible: ['Sencilla', 'Doble', 'Triple', 'Cuadruple']
                            //extendible: ['Sencilla', 'Doble', 'Triple', 'Cuadruple', 'Jr']
                        }
                    },
                    linkRoom_model:{
                        _id:'',
                        room_id: '',
                        base_rate: {
                            value: '',
                            link: ''
                        },
                        extra_pax: {
                            value: '',
                            link: ''
                        },

                        sencilla: {
                            value: '',
                            link: ''
                        },

                        double: {
                            value: '',
                            link: ''
                        },

                        triple: {
                            value: '',
                            link: ''
                        },

                        cuadruple: {
                            value: '',
                            link: ''
                        },

                        jr: {
                            value: '',
                            link: ''
                        },
                    }
                },

                rateConfigsBase: [],
                rateConfigs: [],

                //fuseSearch: null,
                contract: {
                    _id: '',
                    cod: 'TEST1',
                    meal_plan: 3,
                    currency: 'MXN',
                    is_public: false,
                    cancellation_policy: '',
                    property_id: '',
                    status: true,
                    rooms:[/*{
                        _id:'',
                        room_id: '',
                        rateplan: '',
                        cancellation_policy: '',
                        base_rate: 0,
                        extra_pax: 0,
                        sencilla: 0,
                        double: 0,
                        triple: 0,
                        cuadruple: 0,
                        jr:0

                    }*/],
                    payment_options: ['TDD'],
                    link_rateplan: '',
                    link_value: '',
                    link_type: '',
                    room_links: [

                    ],



                    es:{
                        name: 'Contrato nuevo',

                    },
                    en:{
                        name: '',
                    },
                    errors:{
                        rooms: [false],
                        rates: [false],
                        payments: false,
                        racks: false,
                    }
                },
                contract_info: {
                    types: [
                        {
                            _id: 1,
                            name: 'Plan Europeo',
                            status: false
                        },
                        {
                            _id: 2,
                            name: 'Plan Europeo + Desayuno',
                            status: false
                        },
                        {
                            _id: 3,
                            name: 'Todo Incluido',
                            status: false
                        }
                    ],
                    rates: [
                    ],
                    contract_all:[],
                    contracts:[],
                    annulments:[{
                        _id:'',
                        name: 'Seleccionar'
                    }],
                    payments: [
                        {
                            _id: 'TDD',
                            name: 'Tarjeta de crédito/debito',
                            status: false
                        },
                        {
                            _id: 'OXXO',
                            name: 'Depósito OXXO',
                            status: false
                        },
                        {
                            _id: 'PAYPAL',
                            name: 'PayPal',
                            status: false
                        },
                        {
                            _id: 'ARRIVE',
                            name: 'Pago en destino',
                            status: false
                        }
                    ],

                    format_payment:[
                        {
                            _id: '+$',
                            name: '( + ) $',
                            value: '1',
                            type: '$'

                        },
                        {
                            _id: '-$',
                            name: '( - ) $',
                            value: '-1',
                            type: '$'

                        },
                        {
                            _id: '+%',
                            _id: '+%',
                            name: '( + ) %',
                            value: '1',
                            type: '%'
                        },
                        {
                            _id: '-%',
                            name: '( - ) %',
                            value: '-1',
                            type: '%'
                        },
                        {
                            _id: '=',
                            name: '=',
                            value: '0',
                            type: '='
                        }
                    ],
                    currencys:[
                        {
                            _id: 'MXN',
                            name: 'MXN',

                        },
                        {
                            _id: 'USD',
                            name: 'USD',

                        }
                    ],
                    rooms:[],
                    rooms_old: [],
                    rates_old: [],
                    rooms_links_old: [],
                    rooms_charger: []
                },
                /*fplugin:{
                    rooms:{
                        array_list: [],
                        input_add: '',
                        input_status: false,
                        input_search: ''
                    },

                },
                options : {
                    shouldSort: true,
                    matchAllTokens: true,
                    threshold: 0.5,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name"
                    ]
                }
                */

                modelValidations: {
                    name: {
                        required: true
                    },
                    type: {
                        required: true
                    },
                    code:{
                        required: true
                    },
                    currency:{
                        required: true
                    },
                    room_id:{
                        required: true
                    },
                    base_rate:{
                        required: true
                    },
                },
                section: 'ROOMS_RATES'
            }
        },

        mounted() {
            //this.fuseSearch = new Fuse(this.tableData, {keys: ['name']})
            this.changeProperty()
            this.$refs.topProgress.start();
        },
        created(){
          this.initPage(this.selected_property._id);
          this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        methods: {

            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='editContract'){
                        this.$refs.topProgress.start();
                        this.$router.push({
                            name: 'Contracts'
                        });
                    }else{
                        this.initPage(property_id);
                    }
                })
            },
            async initPage(property_id){
                let contracts= [];
                let annulments= [];
                this.contract_info.rooms = [];
                this.contract.property_id = property_id;
                let property = await propertyService.getPropertyId(property_id);
                property = property.data.data.property;
                let paymnt = false;
                let payments_status = false;

                paymnt = this.contract_info.payments.find(payment => payment._id === 'TDD');
                paymnt.status = property.pay_tdc;
                payments_status = await(property.pay_tdc)?property.pay_tdc:payments_status;

                paymnt = this.contract_info.payments.find(payment => payment._id === 'PAYPAL');
                paymnt.status = property.pay_paypal;
                payments_status = await(property.pay_paypal)?property.pay_paypal:payments_status;

                paymnt =  this.contract_info.payments.find(payment => payment._id === 'ARRIVE');
                paymnt.status = property.pay_hotel;
                payments_status = await(property.pay_hotel)?property.pay_hotel:payments_status;

                this.info_page.payments_status = payments_status;

                paymnt = this.contract_info.types.find(payment => payment._id === 1);
                paymnt.status = property.plan_ep;
                paymnt = this.contract_info.types.find(payment => payment._id === 2);
                paymnt.status = property.plan_epb;
                paymnt =  this.contract_info.types.find(payment => payment._id === 3);
                paymnt.status = property.plan_ai;

                let rateplans = await contractService.getContracts('true', property_id);
                this.contract_info.contract_all = rateplans.data.data.rateplans;
                rateplans = rateplans.data.data.rateplans;

                contracts = [{
                    _id: '',
                    name: 'Seleccionar'
                }]
                let contract_id = '0';
                if(this.$route.name==='editContract'){
                    contract_id = this.$route.params.id;
                }

                rateplans.forEach(elem=>{
                    if(!elem.rateplan_link && elem._id !== contract_id){
                        //console.log('contarto base', elem);
                        contracts.push(elem)
                    }
                })

                this.contract_info.contracts = contracts;

                annulmentService.getAnnulments('true', property_id)
                    .then((response) =>  {
                        //console.log('response2', response.data.data.cancellationPolicys)

                        this.contract_info.annulments = this.contract_info.annulments.concat(response.data.data.cancellationPolicys);
                    })
                    .catch(error => {
                       //console.log(error);
                    })
                    .finally(() => {
                    });
                let room_response = await roomService.getRooms('true', property_id)
                room_response = room_response.data.data.rooms

                await room_response.forEach(async tnmt => {
                    /*this.contract_info.rooms.push({
                        _id:tnmt._id,
                        name: tnmt.name,
                        status: true,
                    });*/
                    this.contract_info.rooms_charger.push({
                        _id:tnmt._id,
                        name: tnmt.name,
                        max_pax: tnmt.max_pax,
                        status: true,
                    });

                });
                this.checkRoute(contracts)
                this.$refs.topProgress.done()

            },
            checkRoute(){
                let contract_id = this.$route.params.id;
                this.contract_info.rooms_charger.forEach(roomCharger=>{
                    this.contract_info.rooms.push(roomCharger);
                })
                if(this.$route.name==='editContract'){


                    //let index_ky =this.findIndex(contracts, contract_id);
                    //contracts.splice(index_ky, 1);
                    this.info_page.action = 'edit';
                    this.info_page.header = this.$t('contract.edit.header');
                    this.info_page.button = this.$t('form.update');
                    this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t('contract.edit.header');
                    this.updateData(contract_id);

                }

            },
            async updateData(contract_id) {

                let rateconfigBase = null;
                await contractService.getContractId(contract_id).then(async response => {
                    let data = response.data.data.rateplan;
                   //console.log('contrato', data);
                    //console.log('this.contract_info.contract_all', this.contract_info.contract_all)
                    let plan_actually = this.contract_info.types[data.meal_plan-1];
                    //console.log('plan_actually', plan_actually);


                    this.contract.es.name = data.name;
                    this.contract._id = data._id;
                    this.contract.property_id = data.property_id;
                    this.contract.cod= data.cod;
                    this.contract.is_public = data.is_public;
                    this.contract.meal_plan= (plan_actually.status)?data.meal_plan:'';
                    this.contract.currency= data.currency;
                    this.contract.cancellation_policy= data.cancellation_policy;
                    this.contract.status= data.status;
                    if(this.$route.params.changeActive){
                        this.contract.status = true;
                    }


                    let rateplans = this.contract_info.contracts;
                    let rateplans_aux = [];
                    this.contract_info.contracts = [];

                    rateplans.forEach(elem=>{
                        if(elem.currency === this.contract.currency){
                            //console.log('contarto base', elem);
                            if(this.contract.meal_plan===elem.meal_plan){
                                rateplans_aux.push(elem)
                            }
                        }
                    })

                    this.contract_info.contracts = rateplans_aux;





                    let payment_options= data.payment_options;
                    payment_options.forEach((payment_i, idx)=>{

                        let pay_op = this.contract_info.payments.findIndex(payment => payment._id === payment_i);
                        if(pay_op<0){
                            payment_options.splice(idx, 1)
                        }
                    })

                   //console.log('data.rates_list', data.rates_list);

                    data.rates_list.forEach((itm_, indx)=>{
                        this.contract_info.rates_old.push(itm_._id);
                        //console.log('itm_', itm_);

                        this.contract_info.rates.push({
                            _id: itm_._id,
                            value: itm_.value,
                            name: itm_.name,
                            order: itm_.order
                        })
                    })


                    this.contract.payment_options= payment_options;
                    //console.log('pay_op',  this.contract.payment_options);
                    this.checkType();

                    let link_rateplan = '',
                        link_value = '',
                        link_type = '';
                    if(data.rateplan_link){
                        //console.log('rp', data.rateplan_link);
                        //let info_link = this.this.contract_info.format_payment.find(links => links._id  === data.rateplan_link.type);
                        this.contract_base = false
                        link_value = data.rateplan_link.value;
                        link_rateplan = data.rateplan_link.rateplan;
                        link_type = data.rateplan_link.type
                        if(link_value>0 && link_type ==='$'){
                            link_type = '+$';
                        }else if(link_value<0 && link_type ==='$'){
                            link_type = '-$';
                            link_value = Math.abs(link_value);
                        }else if(link_value>0 && link_type ==='%'){
                            link_type = '+%';
                        }else if(link_value<0 && link_type ==='%'){
                            link_type = '-%';
                            link_value = Math.abs(link_value);
                        }
                        rateconfigBase = await contractService.getRateConfig({rateplan:link_rateplan})
                        rateconfigBase = rateconfigBase.data.data.rateconfigs;

                        this.rateConfigs = rateconfigBase;

                    }
                    this.contract.link_rateplan= link_rateplan;
                    this.contract.link_value=link_value;
                    this.contract.link_type= link_type;

                });




               //console.log('response', rateconfigBase);


                contractService.getRateConfig({rateplan:contract_id}).then(response => {
                    let rateconfigs = response.data.data.rateconfigs;
                    let roomsFilters = [];
                    let roomsAll = this.contract_info.rooms_charger;
                   //console.log('roomsAll', roomsAll);
                    if(rateconfigs.length){
                        this.contract.rooms.splice(0, 1);
                    }

                    let key_base = null;
                    let baseCost = 0;
                    rateconfigs.forEach(async (tnmt, idxKey) => {



                        if(!tnmt.room_link_type && this.contract.meal_plan===3){
                            let idx_room = roomsAll.find(roomElem => roomElem._id === tnmt.room);
                            if(idx_room){
                                this.contract_info.rooms.push(idx_room)
                            }
                            let triple_rate = {}, sencilla_rate = {}, cuadruple_rate = {}, double_rate = {};
                            let index_one = -1, index_third = -1, index_fourth = -1;

                            index_one = tnmt.assignments.findIndex(idx_key => idx_key.cant === 1);
                            index_third = tnmt.assignments.findIndex(idx_key => idx_key.cant === 3);
                            index_fourth = tnmt.assignments.findIndex(idx_key => idx_key.cant === 4);

                            if(index_one>=0){
                                sencilla_rate.value = tnmt.assignments[index_one].value;
                                sencilla_rate.link = tnmt.assignments[index_one].link;
                            }
                            if(index_third>=0){
                                triple_rate.value = tnmt.assignments[index_third].value;
                                triple_rate.link = tnmt.assignments[index_third].link;
                            }
                            if(index_fourth>=0){
                                cuadruple_rate.value = tnmt.assignments[index_fourth].value;
                                cuadruple_rate.link = tnmt.assignments[index_fourth].link;
                            }
                            double_rate.value = tnmt.base_rate;
                            baseCost = tnmt.base_rate

                           //console.log('reults', tnmt)


                            this.contract.room_links.push({
                                _id: tnmt._id,
                                room_id: tnmt.room,
                                rateplan: tnmt.rateplan,
                                cancellation_policy: tnmt.cancellation_policy,
                                double: double_rate,
                                sencilla: sencilla_rate,
                                triple: triple_rate,
                                cuadruple: cuadruple_rate,
                                base_rate: 0,
                                extra_pax: 0
                            })

                            rateconfigs.splice(idxKey, 1);
                        }
                    });

                    /*console.log('this.contract.room_links', this.contract.room_links);
                    console.log('rateconfigs', key_base);*/

                    rateconfigs.forEach(async (tnmt, idxKey) => {

                        let idx_room = roomsAll.find(roomElem => roomElem._id === tnmt.room);
                       //console.log('tnmt', tnmt);
                        if(idx_room){
                            this.contract_info.rooms.push(idx_room)
                        }


                        if(tnmt.room_link_type){
                            this.room_linked = true;
                            this.contract_info.rooms_links_old.push(tnmt._id);
                            if(this.contract.meal_plan<3){
                                this.contract.room_links.push({
                                    _id: tnmt._id,
                                    room_id: tnmt.room,
                                    base_rate: {
                                        value: tnmt.base_rate,
                                        link: tnmt.room_link_type
                                    },
                                    extra_pax: {
                                        value: tnmt.extra_pax,
                                        link: tnmt.room_link_type_pax
                                    },
                                })

                            }else{
                                let triple_rate = {}, sencilla_rate = {}, cuadruple_rate = {}, double_rate = {};
                                let index_one = -1, index_third = -1, index_fourth = -1;

                                //console.log('checanco rc oorden', tnmt)

                                if(this.contract_base){

                                    index_one = tnmt.assignments.findIndex(idx_key => idx_key.cant === 1);
                                    index_third = tnmt.assignments.findIndex(idx_key => idx_key.cant === 3);
                                    index_fourth = tnmt.assignments.findIndex(idx_key => idx_key.cant === 4);

                                    if(index_one>=0){
                                        sencilla_rate.value = tnmt.assignments[index_one].value;
                                        sencilla_rate.link = tnmt.assignments[index_one].link;
                                    }
                                    if(index_third>=0){
                                        triple_rate.value = tnmt.assignments[index_third].value;
                                        triple_rate.link = tnmt.assignments[index_third].link;
                                    }
                                    if(index_fourth>=0){
                                        cuadruple_rate.value = tnmt.assignments[index_fourth].value;
                                        cuadruple_rate.link = tnmt.assignments[index_fourth].link;
                                    }

                                    double_rate.link = tnmt.room_link_type;
                                    double_rate.value = tnmt.base_rate;

                                    this.contract.room_links.push({
                                        _id: tnmt._id,
                                        room_id: tnmt.room,
                                        rateplan: tnmt.rateplan,
                                        cancellation_policy: tnmt.cancellation_policy,
                                        double: double_rate,
                                        sencilla: sencilla_rate,
                                        triple: triple_rate,
                                        cuadruple: cuadruple_rate,
                                        base_rate: 0,
                                        extra_pax: 0
                                    })

                                }
                            }
                        }else{

                            this.contract_info.rooms_old.push(tnmt._id);
                            //console.log('contract_base',this.contract_base);
                            if(this.contract.meal_plan<3){
                                this.contract.rooms.push({
                                    _id: tnmt._id,
                                    room_id: tnmt.room,
                                    rateplan: tnmt.rateplan,
                                    cancellation_policy: tnmt.cancellation_policy,
                                    base_rate: tnmt.base_rate,
                                    extra_pax: tnmt.extra_pax,
                                })
                            }else{


                                let triple_rate = 0, sencilla_rate = 0, cuadruple_rate = 0, double_rate = 0;
                                let index_one = -1, index_third = -1, index_fourth = -1;

                                //console.log(this.contract_base, tnmt)

                                if(this.contract_base){


                                    index_one = tnmt.assignments.findIndex(idx_key => idx_key.cant === 1);
                                    index_third = tnmt.assignments.findIndex(idx_key => idx_key.cant === 3);
                                    index_fourth = tnmt.assignments.findIndex(idx_key => idx_key.cant === 4);

                                    double_rate = tnmt.base_rate;
                                    if(index_one>=0){
                                        sencilla_rate = tnmt.assignments[index_one].value;
                                    }
                                    if(index_third>=0){
                                        triple_rate = tnmt.assignments[index_third].value;
                                    }
                                    if(index_fourth>=0){
                                        cuadruple_rate = tnmt.assignments[index_fourth].value;
                                    }
                                    /*sencilla_rate  = this.formatMoney(sencilla_rate)
                                    double_rate  = this.formatMoney(double_rate)
                                    triple_rate  = this.formatMoney(triple_rate)
                                    cuadruple_rate  = this.formatMoney(cuadruple_rate)*/

                                    this.contract.rooms.push({
                                        _id: tnmt._id,
                                        room_id: tnmt.room,
                                        rateplan: tnmt.rateplan,
                                        cancellation_policy: tnmt.cancellation_policy,
                                        double: double_rate,
                                        sencilla: sencilla_rate,
                                        triple: triple_rate,
                                        cuadruple: cuadruple_rate,
                                    })

                                }else{
                                    let confingItem = rateconfigBase.find(rc_elem => rc_elem.room ===  tnmt.room);

                                    index_one = confingItem.assignments.findIndex(idx_key => idx_key.cant === 1);
                                    index_third = confingItem.assignments.findIndex(idx_key => idx_key.cant === 3);
                                    index_fourth = confingItem.assignments.findIndex(idx_key => idx_key.cant === 4);

                                    double_rate = confingItem.base_rate;
                                    if(index_one>=0){
                                        sencilla_rate = confingItem.assignments[index_one].value;
                                    }
                                    if(index_third>=0){
                                        triple_rate = confingItem.assignments[index_third].value;
                                    }
                                    if(index_fourth>=0){
                                        cuadruple_rate = confingItem.assignments[index_fourth].value;
                                    }

                                    /*console.log('cargando contraTO VINCULADO AI', {
                                    })*/

                                    this.contract.rooms.push({
                                        _id: tnmt._id,
                                        room_id: tnmt.room,
                                        rateplan: tnmt.rateplan,
                                        cancellation_policy: tnmt.cancellation_policy,
                                        double: this.gapFunction(double_rate, this.contract.link_value, this.contract.link_type),
                                        sencilla: this.gapFunction(sencilla_rate, this.contract.link_value, this.contract.link_type),
                                        triple: this.gapFunction(triple_rate, this.contract.link_value, this.contract.link_type),
                                        cuadruple: this.gapFunction(cuadruple_rate, this.contract.link_value, this.contract.link_type)
                                    })
                                }


                            }

                        }

                    });

                   //console.log('roomsFilters;', this.contract.room_links);

                    this.checkDifference();
                    this.checkDifferenceLink();
                    this.chargeRateConfigBase(this.contract.link_rateplan)
                   //console.log('roomsFilters;', roomsFilters);
                    //this.contract_info.rooms = roomsFilters;
                })
            },
            sendForm(action){
                this.validate(action);
            },
            async chargeRateConfigBase(rateplan_id){
                let rateconfigs = [];

                //console.log('info_contact', this.contract.rooms);
                let rc_response = await contractService.getRateConfig({rateplan:rateplan_id});
                rc_response = rc_response.data.data.rateconfigs;
                let base_rate = 0;
                let rooms_info = this.contract.rooms;

                let triple_rate = 0;
                let sencilla_rate = 0;
                let cuadruple_rate = 0;
                let double_rate = 0;




                //console.log('rc_response', this.contract.meal_plan, rc_response)

                await rc_response.forEach((item, key) =>{

                    /*let room_selected = rooms_info.find(elem => elem.room_id === item.room)

                    room_selected.base_rate = rate_calculated
                    room_selected.extra_pax = extra_calculated*/


                    if(this.contract.meal_plan<3){
                        if(base_rate===0){
                            base_rate = item.base_rate;
                            item.room_link_type = '=';
                        }

                        let rate_calculated = this.gapFunction(base_rate, item.base_rate, item.room_link_type);
                        let extra_calculated = this.gapFunction(rate_calculated, item.extra_pax, item.room_link_type_pax);

                        rateconfigs.push({
                            room: item.room,
                            base_rate: rate_calculated,
                            extra_pax: extra_calculated
                        })
                    }else{
                        let item_room = {
                            room: item.room,
                            base_rate: 0,
                            double: item.base_rate,
                        };

                        let index_one = item.assignments.findIndex(idx_key => idx_key.cant === 1);
                        let index_third = item.assignments.findIndex(idx_key => idx_key.cant === 3);
                        let index_fourth = item.assignments.findIndex(idx_key => idx_key.cant === 4);

                        if(base_rate===0){

                            double_rate = item.base_rate;
                            //console.log('rc_response', index_one,  index_third, index_fourth);

                            if(index_one>=0){
                                sencilla_rate = item.assignments[index_one].value;
                                //console.log('sencilla_rate', sencilla_rate);
                            }
                            if(index_third>=0){
                                triple_rate = item.assignments[index_third].value;
                            }
                            if(index_fourth>=0){
                                cuadruple_rate = item.assignments[index_fourth].value;
                            }

                            item.room_link_type = '=';
                        }

                        //console.log('rc_response', sencilla_rate,  triple_rate, cuadruple_rate)

                        if(index_one>=0){
                            item_room.sencilla =   this.gapFunction(sencilla_rate, item.assignments[index_one].value, item.room_link_type);
                            //console.log('sencilla_rate', sencilla_rate);
                        }
                        if(index_third>=0){
                            item_room.triple = this.gapFunction(triple_rate, item.assignments[index_third].value, item.room_link_type);
                        }
                        if(index_fourth>=0){
                            item_room.cuadruple = this.gapFunction(cuadruple_rate, item.assignments[index_fourth].value, item.room_link_type);
                        }



                        rateconfigs.push(item_room);
                    }




                })
                //this.$set(this.rateConfigsBase, rateconfigs )//
                this.rateConfigsBase = await rateconfigs
                //this.ContractInfo();
                //this.contract.rooms = rateconfigs
               //console.log('rate_calculated AI ', this.rateConfigsBase );

            },
            saveItem(){
                let link_info = this.contract_info.format_payment.find(links => links._id  === this.contract.link_type);

                if(link_info){
                    this.contract.link_type = link_info._id;
                    //this.contract.link_value = this.contract.link_value;
                }
                let contract = this.contract;

                contract.status = (contract.cancellation_policy!=='');

                let room_links = contract.room_links;
                /*let contract_id = 'test_id';
                let cancellation_policy = contract.cancellation_policy;*/
                if(contract.meal_plan < 3){
                    this.saveRateplanEp(contract);
                }else{
                    this.saveRateplanAI(contract);
                }


            },

            saveRateplanEp(contract){
                contractService.createContract(contract)
                    .then(async (response) => {

                        let cancellation_policy = response.data.data.rateplan.cancellation_policy;
                        let contract_id = response.data.data.rateplan._id;
                        let rooms = this.contract.rooms;
                        let racks = this.contract_info.rates;

                        if(!this.room_linked){
                            await rooms.forEach(async (item, idx) => {
                                item.rateplan = contract_id;
                                item.cancellation_policy =cancellation_policy;
                                contractService.createRateConfig(item);
                            });
                        }else{


                            let room_base = '';
                            this.saveRateConfig(room_base, contract_id, cancellation_policy);

                            /*await room_links.forEach((item, idx) => {
                                item.rateplan = contract_id;
                                item.cancellation_policy =cancellation_policy;
                                if(idx!==0){
                                    item.room_link =room_links[0].room_id;
                                    item.base_rate.link = item.base_rate.link;
                                }else{
                                    item.base_rate.link = 0;
                                }
                                contractService.createRateConfigVinculate(item);
                            });*/
                        }

                        if(this.contract_base){
                            await racks.forEach(async (item, idx) => {
                                contractService.createRateRack(contract_id, item, idx);
                            });
                        }


                        this.showSwal(this.$t('contract.new.msg'))
                    })
                    .catch(error => {
                        if(error.response.data.err === "RatePlan validation failed: cod: cod debe ser único"){
                            this.alertError();
                        }
                        //console.log('error.response.data', error.response.data);
                    })
                    .finally(() => {
                    });
            },
            saveRateplanAI(contract){
               //console.log('form para enviar', this.room_linked, contract)

               contractService.createContract(contract)
                    .then(async (response) => {

                        let cancellation_policy = response.data.data.rateplan.cancellation_policy;
                        let contract_id = response.data.data.rateplan._id;


                        let racks = this.contract_info.rates;

                        if(!this.room_linked ){
                            let rooms = this.contract.rooms;
                            rooms.forEach(async (item, idx) => {
                                item.rateplan = contract_id;
                                item.cancellation_policy = cancellation_policy;

                                let assingment =[];

                                let dataForm =  {
                                    room: item.room_id,
                                    rateplan: item.rateplan,
                                    cancellation_policy: item.cancellation_policy,
                                }

                                if(this.contract_base){
                                    if(item.sencilla>0){
                                        assingment.push({
                                            cant: 1,
                                            value: item.sencilla
                                        })
                                    }
                                    if(item.triple>0){
                                        assingment.push({
                                            cant: 3,
                                            value: item.triple
                                        })
                                    }
                                    if(item.cuadruple>0){
                                        assingment.push({
                                            cant: 4,
                                            value: item.cuadruple
                                        })
                                    }
                                    dataForm.base_rate = item.double;
                                    dataForm.assignments= assingment
                                }else{
                                    dataForm.assignments= assingment;
                                }
                                contractService.createRateConfigAI(dataForm);
                            });
                        }else{
                            let rooms = this.contract.room_links;

                            rooms.forEach(async (item, idx) => {
                                item.rateplan = contract_id;
                                item.cancellation_policy = cancellation_policy;

                                let assingment =[];

                                let dataForm =  {
                                    room: item.room_id,
                                    rateplan: item.rateplan,
                                    cancellation_policy: item.cancellation_policy,
                                }

                                if(item.sencilla.value>0){
                                    assingment.push({
                                        cant: 1,
                                        value: item.sencilla.value,
                                        link: item.sencilla.link
                                    })
                                }
                                if(item.triple.value>0){
                                    assingment.push({
                                        cant: 3,
                                        value: item.triple.value,
                                        link: item.triple.link
                                    })
                                }
                                if(item.cuadruple.value>0){
                                    assingment.push({
                                        cant: 4,
                                        value: item.cuadruple.value,
                                        link: item.cuadruple.link
                                    })
                                }
                                dataForm.base_rate = item.double.value;
                                if(item.double.link){
                                    dataForm.room_link_type = item.double.link;
                                }
                                dataForm.assignments= assingment

                                contractService.createRateConfigAI(dataForm);
                            });
                        }

                        if(this.contract_base){
                            await racks.forEach(async (item, idx) => {
                                contractService.createRateRack(contract_id, item, idx);
                            });
                        }


                        this.showSwal(this.$t('contract.new.msg'))
                    })
                    .catch(error => {
                        if(error.response.data.err === "RatePlan validation failed: cod: cod debe ser único"){
                            this.alertError();
                        }
                        //console.log('error.response.data', error.response.data);
                    })
                    .finally(() => {
                    });
            },
            updateRateplanAI(item){
               /* item.rateplan = contract_id;
                item.cancellation_policy = cancellation_policy;*/

                let assingment =[];

                let dataForm =  {
                    room: item.room_id,
                    rateplan: item.rateplan,
                    cancellation_policy: item.cancellation_policy,
                }

                if(this.contract_base){
                    if(item.sencilla>0){
                        assingment.push({
                            cant: 1,
                            value: item.sencilla
                        })
                    }
                    if(item.triple>0){
                        assingment.push({
                            cant: 3,
                            value: item.triple
                        })
                    }
                    if(item.cuadruple>0){
                        assingment.push({
                            cant: 4,
                            value: item.cuadruple
                        })
                    }
                    dataForm.base_rate = item.double;
                    dataForm.assignments= assingment
                }else{
                    dataForm.assignments= assingment;
                }

                if(item._id===''){

                    //console.log('create (dataForm)', dataForm);
                    contractService.createRateConfigAI(dataForm);
                }else{
                    dataForm._id = item._id
                    //console.log('update (dataForm)', dataForm);
                    contractService.updateRateConfigAI(dataForm);
                }



            },

            async saveRateConfig(room_base, contract_id, cancellation_policy){
                let room_links = this.contract.room_links;

                if(room_links.length>0){
                    let item = room_links[0];
                    item.rateplan = contract_id;
                    item.cancellation_policy =cancellation_policy;
                    if(room_base===''){
                        room_base = item.room_id
                        item.base_rate.link = 0;
                    }else{
                        item.room_link = room_base;
                        item.base_rate.link = item.base_rate.link;
                    }
                   //console.log('item', item)
                    await contractService.createRateConfigVinculate(item).then(async (response) => {
                        this.contract.room_links.splice(0, 1)
                        this.saveRateConfig(room_base, contract_id, cancellation_policy);
                    });
                }
                return true;
            },

            updateItem(){
                let contract = this.contract;
                contract.status = (contract.cancellation_policy!=='' && contract.status === true);


               //console.log('contract', contract)

                contractService.updateContract(this.contract)
                    .then(async (response) => {
                        //console.log('contrato', response.data);
                        let contract_id = response.data.data.rateplan._id;

                        await this.deleteRooms();
                        this.updatesRooms(contract_id);
                        this.updatesRates(contract_id);

                        this.showSwal(this.$t('contract.edit.msg'));
                    })
                    .catch(error => {
                        //console.log('error', error.response.data.err);
                        if(error.response.data.err === "Validation failed: cod: cod debe ser único"){
                            this.alertError();
                        }
                    })
                    .finally(() => {
                    });
            },

            async deleteRooms(){
                let rooms_old = this.contract_info.rooms_old;
                let rooms_links_old = this.contract_info.rooms_links_old;
                if(!this.room_linked){
                    rooms_links_old.forEach(async (item, idx) => {
                        await contractService.deletetRateConfig(item);
                    });
                }else{
                    rooms_old.forEach(async (item, idx) => {
                        await contractService.deletetRateConfig(item);
                    });

                }
            },

            async updatesRooms(contract_id){
                let cancellation_policy =  this.contract.cancellation_policy;

               //console.log('item room linked', this.room_linked);
                if(!this.room_linked){


                    let rooms = this.contract.rooms;
                    let rooms_old = this.contract_info.rooms_old;

                    rooms.forEach(async (item, idx) => {
                        let index = rooms_old.indexOf(item._id);
                        item.rateplan = contract_id;
                        item.cancellation_policy =cancellation_policy;

                        (index>=0)?rooms_old.splice(index, 1):'';


                        if(item._id!==''){
                            if(this.contract.meal_plan < 3){
                                await contractService.updateRateConfig(item);
                            }else{

                                this.updateRateplanAI(item);

                            }
                        }else{
                            await contractService.createRateConfig(item);
                        }


                    });


                    rooms_old.forEach(async (item, idx) => {
                        await contractService.deletetRateConfig(item);
                    });




                }else{

                    let room_links = this.contract.room_links;
                    let rooms_links_old = this.contract_info.rooms_links_old;

                    room_links.forEach(async (item, idx) => {
                       //console.log('item room linked', item);
                        let index = rooms_links_old.indexOf(item._id);
                        let assingment = [];

                        item.rateplan = contract_id;
                        item.room = item.room_id;
                        item.cancellation_policy = cancellation_policy;

                        (index>=0)?rooms_links_old.splice(index, 1):'';
                        if(this.contract.meal_plan===3){
                            if(item.sencilla.value>0){
                                assingment.push({
                                    cant: 1,
                                    value: Number(item.sencilla.value),
                                    link: item.sencilla.link
                                })
                            }
                            if(item.triple.value>0){
                                assingment.push({
                                    cant: 3,
                                    value:Number( item.triple.value),
                                    link: item.triple.link
                                })
                            }
                            if(item.cuadruple.value>0){
                                assingment.push({
                                    cant: 4,
                                    value: Number(item.cuadruple.value),
                                    link: item.cuadruple.link
                                })
                            }
                            item.base_rate = item.double.value;
                            if(item.double.link){
                                item.room_link_type = item.double.link;
                            }
                            item.assignments= assingment
                            delete item.extra_pax;

                            if(item._id!==''){
                                await contractService.updateRateConfigAI(item);
                            }else{

                                await contractService.createRateConfigAI(item);
                            }


                        }else{
                            if(item._id!==''){
                                await contractService.updateRateConfigVinculate(item);
                            }else{

                                if(idx!==0){
                                    item.room_link =room_links[0].room_id;
                                    item.base_rate.link = item.base_rate.link;
                                }else{
                                    item.base_rate.link = 0;
                                }
                                await contractService.createRateConfigVinculate(item);
                            }
                        }

                       //console.log('item room linked', item);



                    });


                    rooms_links_old.forEach(async (item, idx) => {
                        await contractService.deletetRateConfig(item);
                    });


                }
            },

            async updatesRates(contract_id){

                    let rates = this.contract_info.rates;
                    let rates_old = this.contract_info.rates_old;

                    rates.forEach(async (item, idx) => {
                        let index = rates_old.indexOf(item._id);
                        (index>=0)?rates_old.splice(index, 1):'';
                        if(item._id!==''){
                            await contractService.updateRateRack(contract_id, item, idx);
                           //console.log('itentando actualizar rate')
                        }else{
                            await contractService.createRateRack(contract_id, item, idx);
                        }
                    });
                    rates_old.forEach(async (item, idx) => {

                       //console.log('eliminando rate_old')
                        await contractService.deleteRateRack(contract_id, item);
                    });

            },

            showSwal (msg) {

                    swal({
                        title: this.$t('contract.new.modal'),
                        text: msg,
                        buttonsStyling: false,
                        confirmButtonClass: 'btn btn-success btn-fill',
                        type: 'success'
                    }).then(()=> {
                        this.$router.push({
                            name: 'Contracts'
                        });
                    });
            },

            getError (fieldName) {
                return this.errors.first(fieldName);
            },
            validate (action) {

               //console.log('action?', action)
                this.customValidity();
                this.$validator.validateAll().then(isValid => {
                    if(isValid){
                        if( this.customValidity()){
                            if(action==='add'){
                                this.saveItem();
                            }else{
                                this.updateItem();
                            }
                        }

                    }
                   //console.log('validado?', isValid, this.customValidity())
                    //this.$emit('on-submit', this.registerForm, isValid)
                })
            },
            customValidity(){
                let rooms = this.contract.rooms;
                let payments = this.contract.payment_options;
                let racks = this.contract_info.rates;
                let errores = this.contract.errors;
                //console.log('start validity', payments)
                //let max_children = parseInt(this.room.max_children);
                let error_response = true;


                rooms.forEach((room, idx)=>{
                    if(room.room_id===''){
                        error_response = false;
                        errores.rooms[idx] = true;
                    }
                    /*if(room.base_rate==='' || isNaN(room.base_rate)){
                        error_response = false;
                        errores.rates[idx] = true;
                    }*/
                })

                racks.forEach((rack, idx)=>{
                    if(rack.value===''){
                        error_response = false;
                        errores.racks= true;
                    }
                })





                if(payments.length===0){
                    error_response = false;
                    errores.payments = true;

                }
               //console.log('verify validity', error_response, errores)



                return error_response;
            },
            changeLinkType(type_){
                if(type_==='='){
                    this.contract.link_value = 0;
                }
                this.ContractInfo()


            },
            async contractType(){
              let type_contract = this.contract.link_rateplan;
              let contracts = this.contract_info.contract_all;



              if(type_contract===''){
                 //console.log('reset rooms')
                  this.contract_base = true;
                  this.contract.currency = '';
                  this.contract.meal_plan = '';
                  this.contract.link_type = '';
                  this.contract.link_value = '';

                  this.contract.rooms = [];



              }else{

                  await this.getContract(type_contract);

                  let contract_info = contracts.find(item => item._id === type_contract)

                 //console.log('type_contract',  type_contract, contract_info)
                  this.contract.currency = contract_info.currency;
                  this.contract.meal_plan = contract_info.meal_plan;
                  this.contract_base = false;
                  this.chargeRateConfigBase(type_contract)
              }
                this.contract_info.rooms = this.contract_info.rooms_charger;

            },

            alertError(){
                    swal({
                        title: 'Código de contrato ya existe',
                        type: 'warning',
                        confirmButtonClass: 'btn btn-danger btn-fill',
                        confirmButtonText: 'Modificar',
                        buttonsStyling: false
                    }).then((result)=> {

                    });

            },

            changeCurrency(){
                let currency = this.contract.currency;
                let contracts = this.contract_info.contract_all;
                this.contract_info.contracts = contracts.filter( item=> item.currency === currency);
               //console.log('rates_plans', contracts.filter( item=> item.currency === currency));
            },

            addRoomLink(){
                let model_roomLink = JSON.parse(JSON.stringify(this.info_page.linkRoom_model));
                this.contract.room_links.push(model_roomLink);
                this.checkDifferenceLink();

            },

            deleteLinkRoom(idx){
                this.contract.room_links.splice(idx, 1)
            },
            checkType(){
               let type_is = this.contract.meal_plan;
               this.show_extendible = (type_is===3);

            },
            contractMealplan(meal_plan){


                let contractTypes = this.contract_info.types;
                let valueT = '';

                if(meal_plan!==''){
                    let contract_info = contractTypes.find(item => item._id === meal_plan)
                    valueT = contract_info.name;
                }

                return valueT;

            },
            roomName(room_id){
                //console.log('roomName', room_id)
                if(room_id){
                    let rooms = this.contract_info.rooms;

                    let room_info = rooms.find(itm => itm._id===room_id)

                    return room_info.name;
                }


            },

            ContractInfo(){
                let contract_id = this.contract.link_rateplan
                let link_value = Number(this.contract.link_value)
                let link_type = this.contract.link_type




               //console.log('contract_id:', contract_id)
               //console.log('link_value:', link_value)
               //console.log('link_type:', link_type)

                let rooms = this.contract.rooms;
                let rate_config2 = this.rateConfigs;
                let rate_config = this.rateConfigsBase;



               //console.log('item.room', this.contract.meal_plan)

               //console.log('rate_config2', rate_config2)
                let room_items = [];

                let rateConfigBase =  {};

                rate_config2.forEach((item, idx)=>{

                   //console.log('rate_link', item)
                    if(!item.room_link){
                        rateConfigBase = {
                            base_rate: item.base_rate,
                            extra_pax:  this.gapFunction(item.base_rate, item.extra_pax, item.room_link_type_pax),

                        };
                    }
                    if(this.contract.meal_plan===3){

                       //console.log('meal_plan 3', this.contract.meal_plan)
                    }
                });

                //console.log('rate_config2', rate_config2)


                if(link_value !== '' && link_type !==''){
                    rooms.forEach((item, idx)=>{

                        let config = rate_config2.find(itm => itm.room===item.room_id)
                       //console.log('config', config)
                        let contract_ai = this.contract.meal_plan===3;
                       //console.log('item', item, contract_ai)
                        let variableBr = 0;

                        let triple_rate = 0, sencilla_rate = 0, cuadruple_rate = 0, double_rate = 0;
                        let index_one = -1, index_third = -1, index_fourth = -1;
                        if(contract_ai){

                            index_one = config.assignments.findIndex(idx_key => idx_key.cant === 1);
                            index_third = config.assignments.findIndex(idx_key => idx_key.cant === 3);
                            index_fourth = config.assignments.findIndex(idx_key => idx_key.cant === 4);
                        }


                        switch (link_type) {
                            case '+$':
                                if(config.room_link){
                                    variableBr = this.gapFunction(rateConfigBase.base_rate, config.base_rate, config.room_link_type) + link_value
                                }else{
                                    variableBr = config.base_rate + link_value
                                }

                                if(contract_ai){
                                    double_rate = variableBr;
                                    if(index_one>=0){
                                        sencilla_rate = config.assignments[index_one].value + link_value;
                                    }
                                    if(index_third>=0){
                                        triple_rate = config.assignments[index_third].value + link_value;
                                    }
                                    if(index_fourth>=0){
                                        cuadruple_rate = config.assignments[index_fourth].value + link_value;
                                    }
                                }

                                break;
                            case '-$':

                                if(config.room_link){
                                    variableBr = this.gapFunction(rateConfigBase.base_rate, config.base_rate, config.room_link_type) - link_value
                                }else{
                                    variableBr = config.base_rate - link_value
                                }

                                if(contract_ai){
                                    double_rate = variableBr;
                                    if(index_one>=0){
                                        sencilla_rate = config.assignments[index_one].value - link_value;
                                    }
                                    if(index_third>=0){
                                        triple_rate = config.assignments[index_third].value - link_value;
                                    }
                                    if(index_fourth>=0){
                                        cuadruple_rate = config.assignments[index_fourth].value - link_value;
                                    }
                                }


                                break;
                            case '-%':
                                if(config.room_link){
                                    variableBr = this.gapFunction(rateConfigBase.base_rate, config.base_rate, config.room_link_type)
                                    variableBr = variableBr - variableBr/100*link_value;
                                }else{
                                    variableBr = config.base_rate - config.base_rate/100*link_value;
                                }
                                if(contract_ai){
                                    double_rate = variableBr;
                                    if(index_one>=0){
                                        sencilla_rate = config.assignments[index_one].value - config.assignments[index_one].value/100*link_value;

                                    }
                                    if(index_third>=0){
                                        triple_rate = config.assignments[index_third].value - config.assignments[index_third].value/100*link_value;
                                    }
                                    if(index_fourth>=0){
                                        cuadruple_rate = config.assignments[index_fourth].value - config.assignments[index_fourth].value/100*link_value;
                                    }
                                }

                                break;
                            case '+%':
                                if(config.room_link){
                                    variableBr = this.gapFunction(rateConfigBase.base_rate, config.base_rate, config.room_link_type)
                                    variableBr = variableBr + variableBr/100*link_value;
                                }else{
                                    variableBr = config.base_rate + config.base_rate/100*link_value;
                                }
                                if(contract_ai){
                                    double_rate = variableBr;
                                    if(index_one>=0){
                                        sencilla_rate = config.assignments[index_one].value + config.assignments[index_one].value/100*link_value;

                                    }
                                    if(index_third>=0){
                                        triple_rate = config.assignments[index_third].value + config.assignments[index_third].value/100*link_value;
                                    }
                                    if(index_fourth>=0){
                                        cuadruple_rate = config.assignments[index_fourth].value + config.assignments[index_fourth].value/100*link_value;

                                    }
                                }

                                //item.extra_pax = config.extra_pax;
                                break;
                            default:
                                item.base_rate =config.base_rate;
                                item.extra_pax = config.extra_pax;
                                if(contract_ai){
                                    double_rate = item.base_rate;
                                    if(index_one>=0){
                                        sencilla_rate = config.assignments[index_one].value;

                                    }
                                    if(index_third>=0){
                                        triple_rate = config.assignments[index_third].value;
                                    }
                                    if(index_fourth>=0){
                                        cuadruple_rate = config.assignments[index_fourth].value;
                                    }
                                }
                                break;
                        }
                        item.extra_pax = this.formatMoney(this.gapFunction(variableBr, config.extra_pax, config.room_link_type_pax));
                        item.base_rate = this.formatMoney(variableBr);
                        item.double = this.formatMoney(double_rate);
                        item.triple = this.formatMoney(triple_rate);
                        item.cuadruple = this.formatMoney(cuadruple_rate);
                        item.sencilla = this.formatMoney(sencilla_rate);



                    });
                }
                else if(this.contract_base && this.room_linked){
                    let  room_links = this.contract.room_links;
                    let baseRate = 0;
                    if(room_links){
                        baseRate = room_links[0].double.value;

                    }
                    /*room_links.forEach(roomLink=>{


                        //roomLink.sencilla.value = this.gapFunction(baseRate, )

                        console.log('este es un contrato base con habitacion vinculada', roomLink)
                    })*/
                }
            },

            gapFunction(base, value, link_type){
                let result = 0;
                base = Number(base);
                value = Number(value);
                //console.log('gapFunction', base, value, link_type)

                switch (link_type) {
                   case '+$':
                       result = (base + value);
                       break;

                   case '-$':
                       result = (base - value);
                       break;

                   case '-%':
                       result = (base - (base/100*value));

                       break;
                   case '+%':
                       result = (base + (base/100*value));
                       break;
                    case '=':
                        result = value;
                        break;
                   default:
                       result = 0;
                       break;
               }
               return result

            },

            gapOnly(base, value, link_type){
                let result = 0;
                base = Number(base);
                value = Number(value);

                switch (link_type) {
                    case '+$':
                        result = (base + value);
                        break;

                    case '-$':
                        result =  (base - value);
                        break;

                    case '-%':
                        result =  (base - (base/100*value));

                        break;
                    case '+%':
                        result =  (base + (base/100*value));
                        break;
                    case '=':
                        result = value;
                        break;
                    default:
                        result = 0;
                        break;
                }
                return result

            },

            calculateResults(idx_, type){

                let room_links = this.contract.room_links;
                let base_link = room_links[0];
                let value_aux = 0;
                /*let aux_obj = {
                    room_id: '',
                    base_rate: 0,
                    extra_pax: 0,
                    sencilla: 0,
                    double: 0,
                    triple: 0,
                    cuadruple: 0,
                    jr: 0,
                }*/

                if(idx_ === 0){
                    let link = room_links[idx_]
                    switch (type) {
                        case 'base_rate':
                            value_aux = this.gapFunction(base_link.base_rate.value, link.base_rate.value, "=")
                            break;

                        case 'extra_pax':
                            value_aux =this.gapOnly(base_link.base_rate.value, link.extra_pax.value, link.extra_pax.link)
                            break;
                    }
                }else{
                    let link = room_links[idx_];

                    switch (type) {

                        case 'base_rate':
                            value_aux = this.gapFunction(base_link.base_rate.value, link.base_rate.value, link.base_rate.link)
                            break;
                        case 'extra_pax':
                            let pre_value =  this.gapFunction(base_link.base_rate.value, link.base_rate.value, link.base_rate.link)
                            value_aux =this.gapOnly(pre_value, link.extra_pax.value, link.extra_pax.link)
                            break;
                    }
                }

                return this.formatMoney(value_aux);
            },

            calculateResultsAI(idx_, type){
                //console.log('idx_, type', idx_, type)

                let room_links = this.contract.room_links;
                let roomLinkChoose = this.contract.room_links[idx_];
                let baseRate = room_links[0].double.value;
                let value_aux = 0;

                if(idx_ >  0){
                    baseRate = this.gapFunction(baseRate, roomLinkChoose.double.value, roomLinkChoose.double.link)
                }

                //console.log('baseRate', baseRate)

                switch (type) {

                    case 'sencilla':
                        value_aux =  this.gapFunction(baseRate, roomLinkChoose.sencilla.value, roomLinkChoose.sencilla.link);
                        break;
                    case 'double':
                        value_aux = baseRate;
                        break;
                    case 'triple':
                        value_aux =  this.gapFunction(baseRate, roomLinkChoose.triple.value, roomLinkChoose.triple.link);
                        break;
                    case 'cuadruple':
                        value_aux =  this.gapFunction(baseRate, roomLinkChoose.cuadruple.value, roomLinkChoose.cuadruple.link);
                        break;
                }
                //console.log('value_aux', value_aux)


                if(value_aux){

                    return this.formatMoney(Number(value_aux));
                }
                else{
                    return 0;
                }
            },


            formatMoney(value){
                return value.toFixed(2)
            },

            async getContract(){
                let contract_id = this.contract.link_rateplan
                let rateConfigs = await contractService.getRateConfig({rateplan:contract_id})
                let rooms = this.contract_info.rooms_charger;
                let room_items = [];
                rateConfigs = rateConfigs.data.data.rateconfigs;
                this.contract.rooms = [];
                this.rateConfigs = rateConfigs;
                this.contract_info.rooms = [];
                let idx_delete = null;

                let rateConfigBase =  {};
                let baseCost = 0;

               //console.log('rateConfigs', rateConfigs, this.contract.meal_plan)




                await rateConfigs.forEach((item, idx)=>{

                   //console.log('rate_link', item)
                    if(!item.room_link){
                        rateConfigBase = {
                            base_rate: item.base_rate,
                            extra_pax:  this.gapFunction(item.base_rate, item.extra_pax, item.room_link_type_pax),

                        };
                    }
                    if(!item.room_link === this.contract.meal_plan){

                        baseCost =  item.base_rate
                        /*rateConfigBase = {
                            base_rate: item.base_rate,
                            extra_pax:  this.gapFunction(item.base_rate, item.extra_pax, item.room_link_type_pax),

                        };*/
                    }
                });


                /*if(idx_delete){
                    rateConfigs= rateConfigs.splice(idx_delete, 1)
                }*/


                //console.log('rateConfigs', rateConfigs)
                rateConfigs.forEach((item, idx)=>{

                    let room_info = rooms.find(itm => itm._id===item.room)
                    this.contract_info.rooms.push({
                        _id:room_info._id,
                        name: room_info.name,
                        status: true,
                    });
                    // this.$set(this.room.beds[idx_arr].types[idx], 'status', false);

                    /*let model_roomLink = JSON.parse(JSON.stringify(this.info_page.linkRoom_model));
                    model_roomLink.room_id = item.room*/

                    let double_rate = 0, sencilla_rate = 0, triple_rate = 0, cuadruple_rate = 0;


                    let index_one = item.assignments.findIndex(idx_key => idx_key.cant === 1);
                    let index_third = item.assignments.findIndex(idx_key => idx_key.cant === 3);
                    let index_fourth = item.assignments.findIndex(idx_key => idx_key.cant === 4);

                    if( item.assignments.length>0){


                        double_rate = item.base_rate;
                        if(baseCost){
                            double_rate = double_rate;
                        }
                        //if
                        //console.log('rc_response', index_one,  index_third, index_fourth);

                        if(index_one>=0){
                            sencilla_rate = item.assignments[index_one].value;
                            //console.log('sencilla_rate', sencilla_rate);
                            if(item.assignments[index_one].link){
                                sencilla_rate = this.gapFunction(double_rate, item.assignments[index_one].value, item.assignments[index_one].link)
                            }
                            //sencilla_rate =   this.gapFunction(sencilla_rate, item.assignments[index_one].value, item.room_link_type);

                        }
                        if(index_third>=0){
                            triple_rate = item.assignments[index_third].value;
                            if(item.assignments[index_third].link){
                                triple_rate = this.gapFunction(double_rate, item.assignments[index_third].value, item.assignments[index_third].link)
                            }
                            //triple_rate = this.gapFunction(triple_rate, item.assignments[index_third].value, item.room_link_type);
                        }
                        if(index_fourth>=0){
                            cuadruple_rate = item.assignments[index_fourth].value;
                            if(item.assignments[index_fourth].link){
                                cuadruple_rate = this.gapFunction(double_rate, item.assignments[index_fourth].value, item.assignments[index_fourth].link)
                            }
                            //cuadruple_rate = this.gapFunction(cuadruple_rate, item.assignments[index_fourth].value, item.room_link_type);
                        }

                        //item.room_link_type = '=';
                        double_rate = this.formatMoney(double_rate);
                        sencilla_rate = this.formatMoney(sencilla_rate);
                        triple_rate = this.formatMoney(triple_rate);
                        cuadruple_rate = this.formatMoney(cuadruple_rate);
                    }




                    //console.log('item.room', item, rateConfigBase)
                    if(!item.room_link){
                        this.contract.rooms.push({
                            _id: '',
                            base_rate:  this.formatMoney(item.base_rate),
                            room_id: item.room,
                            extra_pax: this.formatMoney(this.gapFunction(item.base_rate, item.extra_pax, item.room_link_type_pax)),
                            sencilla: sencilla_rate,
                            double: double_rate,
                            triple: triple_rate,
                            cuadruple: cuadruple_rate,
                            jr:0,

                        });
                    }else{
                        this.contract.rooms.push({
                            _id: '',
                            base_rate:  this.formatMoney(this.gapFunction(rateConfigBase.base_rate, item.base_rate, item.room_link_type)),
                            room_id: item.room,
                            extra_pax: this.formatMoney(this.gapFunction(this.gapFunction(rateConfigBase.base_rate, item.base_rate, item.room_link_type), item.extra_pax, item.room_link_type_pax)),
                            sencilla: sencilla_rate,
                            double: double_rate,
                            triple: triple_rate,
                            cuadruple: cuadruple_rate,
                            jr:0,

                        });
                    }

                })

                //this.checkDifference();
               //console.log('this.contract.rooms', this.contract.rooms)

            },

            /******************* FUNCIONES PARA TEXTAREA MULTIPLE VERSION ADRIAN **************************************/

            addRoom(){

                this.contract.rooms.push({
                    _id:'',
                    base_rate: 0,
                    room_id: '',
                    extra_pax: 0,
                    sencilla: 0,
                    double: 0,
                    triple: 0,
                    cuadruple: 0,
                    jr:0

                })
                this.contract.errors.rooms.push(false);
                this.contract.errors.rates.push(false);
                this.checkDifference();
            },
            deleteRoom(n){
                this.contract.rooms.splice(n, 1)
                this.contract.errors.rooms.splice(n, 1)
                this.contract.errors.rates.splice(n, 1)
                this.checkDifference();
            },
            checkDifference(n=null, type=null){

               //console.log('checkDifference', n, type)
                if(n!==null){
                    this.contract.errors.rooms[n] = false
                }
                let  selected_rooms = this.contract.rooms;
                let full_rooms = this.contract_info.rooms;
                let  rateConfigs = [];
                full_rooms.forEach(item => {

                    item.status = true;
                });
                let contract_id = this.contract.link_rateplan

                selected_rooms.forEach(item => {

                    if(type==='change' && contract_id!==''){

                       this.ContractInfo();
                    }
                    //console.log('encontrados',full_rooms.findIndex(room => room._id === item.room_id))
                    let idx = full_rooms.findIndex(room => room._id === item.room_id);
                    if(idx>=0){
                        full_rooms[idx].status = false;
                    }
                })
            },
            checkDifferenceLink(n=null, type=null){

               //console.log('checkDifferenceLink', n, type)
                if(n!==null){
                    this.contract.errors.rooms[n] = false
                }
                let  selected_rooms = this.contract.room_links;
                let full_rooms = this.contract_info.rooms;
                let  rateConfigs = [];
                full_rooms.forEach(item => {

                    item.status = true;
                });
                let contract_id = this.contract.link_rateplan

                selected_rooms.forEach(item => {

                    if(type==='change' && contract_id!==''){
                        this.ContractInfo();
                    }
                    //console.log('encontrados',full_rooms.findIndex(room => room._id === item.room_id))
                    let idx = full_rooms.findIndex(room => room._id === item.room_id);
                    if(idx>=0){
                        full_rooms[idx].status = false;
                    }
                })
            },
            findIndex(arr, id) {
                let obj = arr.find(o => o._id === id);
                return  arr.indexOf(obj);
            },

            /******************************************** RACKS **************************************************************/

            addRack(){
                if(this.contract_info.rates.length<10)
                {

                    let racks_length = this.contract_info.rates.length;
                    let acomp = '';
                    if(racks_length===0){

                        acomp = 'Rack';
                    }else if(racks_length===1){
                        acomp = 'Bar';
                    }else{
                        acomp = 'Bar'+this.contract_info.rates.length;
                    }

                    this.contract_info.rates.push({
                        _id: '',
                        name: acomp,
                        value: ''

                    })
                }



            },
            deleteRack(key){

                let array_aux = this.contract_info.rates;
                if(array_aux.length>0){
                    array_aux.splice(key, 1);
                }

                array_aux.forEach((item, idx)=>{
                    let acomp = '';
                    if(idx===0){
                        acomp = 'Rack';
                    }else if(idx>0){
                        acomp = 'Bar';
                        acomp+=(idx>1)?idx:'';
                    }

                    item.name = acomp;
                })



            },



            /**********************************************************************************************************/
        }
    }

</script>
<style lang="scss">
    .form-check {
        &.is_public{
            margin-top: 35px;
            .form-check-label{
                text-transform: uppercase;
            }
        }
    }


    .container-images {
        padding-top: 35px;
        .principal {
            width: 100%;
            height: 175px;
            margin-bottom: 10px;
        }
        .second{
            width: 30%;
            margin-left: 5%;
            &.first{
                margin-left: 0;
            }
        }
    }
    .title-b{
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 0;
        font-weight: 400;
    }
    .box-rooms{
        width: 100%;
        margin-bottom: 25px;
        .text-muestra{
            font-size: 14px;
            line-height: 40px;
            margin-bottom: 0;
        }
        .item-minus{
            line-height: 45px;
        }
        .form-group{
            margin-bottom: 0;
        }
        .btn-plus{
            -webkit-box-shadow: 0 10px 6px -4px #777;
            -moz-box-shadow: 0 10px 6px -4px #777;
            box-shadow: 0 10px 6px -4px #777;
            width: 35px;
            height: 35px;
            font-size: 28px;
            background-color: black;
            display: inline-block;
            border-radius: 100%;
            position: relative;
            right: 45px;
            i{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 4px;
                bottom: 0px;
                vertical-align: middle;
                display: block;
                color: #fff;

            }
        }
    }
    .box-button{
        //padding-left: 10px;
        .add-item{
            width: 100% ;
            height: 40px;
            color: #fff;
            background-color: #222222;
            border: 2px solid #000;
        }
    }
    .btn-action {
        line-height: 45px;
        font-size: 25px;
        margin-left: 15px;
    }
    .card{
        .lbl-main{
            font-weight: 600;
            margin-top: 50px;
        }

        .pt-custom{
            margin-top: 26px !important;
        }

    }
    .el-tag, .el-tag.el-tag--default {
        background-color: #eee;
    }
    .custom-error{
        label.text-danger{
            text-transform: none;
            white-space: nowrap;
        }
    }
    span.item-empty{
        font-size: 11px;
        width: 100%;
        text-align: center;
        text-transform: none;
        font-style: italic;
        color: #CACACA;
        display: inline-block;
    }
    .form-rates-ext{
        label{
            width: 70px;
        }
        .form-group{
            width: calc(100% - 100px);
            display: inline-block;
        }
        span{
            width: 30px;
        }
        span.plus-item{
            float: right;
            font-size: 25px;
        }
    }



</style>
